import * as Yup from "yup"

export const membershipFormSchema = Yup.object().shape({
  number_of_churches_pastors: Yup.number().required(
    "please add a valid number from 1"
  ),
  title: Yup.string()
    .test("is-not-select", "required", value => {
      return value !== "select"
    })
    .required("required"),
  leader: Yup.string()
    .matches(/\w\s\w/, "please include surname too")
    .min(1, "too short!")
    .required("required"),
  organisation_name: Yup.string().required("required"),
  designation: Yup.string()
    .test("is-not-select", "required", value => {
      return value !== "select"
    })
    .required("required"),
  address: Yup.string()
    .test(
      "is-valid",
      "please select a valid address from the drop down",
      (value, context) => {
        return value === context.parent.confirm_address
      }
    )
    .required("required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "please enter a valid 10 digit number")
    .min(10, "please enter a valid 10 digit number")
    .max(10, "please enter a valid 10 digit number")
    .required("required"),
  email: Yup.string().email("invalid email").required("required"),
  emailConfirm: Yup.string()
    .email("invalid email")
    .test("is-equal", "email address is not the same", (value, context) => {
      return value === context.parent.email
    })
    .required("required"),
})
