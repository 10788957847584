import { Loader } from "google-maps"

const initGoogle = async () => {
  const loader = new Loader("AIzaSyCIHtXUPaauxKkDCwJlv1sQMTaZs-fkqUo", {
    libraries: ["places"],
  })
  return await loader.load()
}

export default initGoogle
