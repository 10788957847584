import * as React from "react"
import "./membership-form.css"
import { Formik, Form } from "formik"
import InputField from "../Fields/input-field"
import SelectField from "../Fields/select-field"
import { membershipFormSchema } from "./membership-form-schema"
import { submitMembershipForm } from "./membership-form-factory"
import FormAlert from "../Indicators/form-alert"
import FormLoadingIndicator from "../Indicators/form-loading-indicator"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { AddressField } from "../Fields/address-field"

const handleMembershipFormSubmission = (response, setStatus, setSubmitting) => {
  if (response.status === 200) {
    setStatus({
      sent: true,
      message: response.message,
    })
    setSubmitting(false)
  } else {
    setStatus({
      sent: false,
      message: response.error.message,
    })
    setSubmitting(false)
  }
}

const titles = [
  { name: "Mr" },
  { name: "Ms" },
  { name: "Mrs" },
  { name: "Pastor" },
  { name: "Bishop" },
  { name: "Reverend" },
  { name: "Apostle" },
  { name: "Doctor" },
  { name: "Professor" },
]

const designations = [
  { name: "Member" },
  { name: "Regional Leader" },
  { name: "Provincial Leader" },
  { name: "President" },
  { name: "Chairperson" },
  { name: "Secretary" },
  { name: "Deputy Chairperson" },
  { name: "Deputy President" },
  { name: "Executive Council Member" },
]

const MembershipForm = () => {
  return (
    <Formik
      initialValues={{
        organisation_name: "",
        title: "",
        leader: "",
        designation: "",
        phone: "",
        email: "",
        emailConfirm: "",
        address: "",
        confirm_address: "",
        number_of_churches_pastors: 1,
      }}
      validationSchema={membershipFormSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true)
        const response = await submitMembershipForm(values)
        trackCustomEvent({
          category: "Membership Form Submision Button",
          action: "Click",
          label: "Form Submission",
        })
        handleMembershipFormSubmission(response, setStatus, setSubmitting)
      }}
    >
      {({ isSubmitting, isValid, status, resetForm, setStatus }) => (
        <Form id={"membership-form"}>
          <div className="form-row">
            <div className="col-md-2 form-group">
              <SelectField
                label={"Title"}
                name={"title"}
                placeholder={"select"}
                options={titles}
              />
            </div>
            <div className="col-md-4 form-group">
              <SelectField
                label={"Designation of Leader"}
                name={"designation"}
                placeholder={"select"}
                options={designations}
              />
            </div>
            <div className="col-md-6 form-group">
              <InputField
                label={"Leader of Organisation"}
                name={"leader"}
                placeholder={"Name Surname"}
                type={"text"}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12 form-group">
              <InputField
                label={"Fraternal/Organisation Name"}
                name={"organisation_name"}
                placeholder={"Organisation Name"}
                type={"text"}
              />
            </div>
            <div className="col-md-4 form-group">
              <InputField
                label={"Number of Churches/Pastors"}
                name={"number_of_churches_pastors"}
                placeholder={"1"}
                min={1}
                type={"number"}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-6 form-group">
              <InputField
                label={"Email"}
                name={"email"}
                placeholder={"example@mail.com"}
                type={"email"}
              />
            </div>
            <div className="col-md-6 form-group">
              <InputField
                label={"Confirm Email"}
                name={"emailConfirm"}
                placeholder={"example@mail.com"}
                type={"email"}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 form-group">
              <InputField
                label={"Phone"}
                name={"phone"}
                placeholder={"000 000 0000"}
                type={"tel"}
              />
            </div>
            <div className="col-md-8 form-group">
            <AddressField />
            </div>
          </div>
          <div className="mb-3">
            {isSubmitting && (
              <FormLoadingIndicator message={"submitting form"} />
            )}
            {status && status.message && (
              <FormAlert
                status={status}
                onClick={() => {
                  if (status.sent) {
                    resetForm()
                  } else {
                    setStatus(null)
                  }
                }}
              />
            )}
          </div>
          <div className="text-center">
            <button
              type="submit"
              disabled={isSubmitting || !isValid}
              aria-disabled={isSubmitting || !isValid}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default MembershipForm
