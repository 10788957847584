import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import InputField from "./input-field"
import initGoogle from "../google"

export const AddressField = () => {
  const [
    autocomplete,
    setAutocomplete,
  ] = useState<google.maps.places.Autocomplete>()
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    initGoogle().then(g => {
      setAutocomplete(
        new g.maps.places.Autocomplete(
          document.getElementById("address") as HTMLInputElement,
          {
            componentRestrictions: { country: ["za"] },
            fields: ["formatted_address"],
            types: ["address"],
          }
        )
      )
    })
  }, [])

  useEffect(() => {
    if (autocomplete)
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace().formatted_address
        setFieldValue("confirm_address", place)
        setFieldValue("address", place)
      })
  }, [autocomplete, setAutocomplete])

  return (
    <>
      <InputField
        label={"Address"}
        name={"address"}
        placeholder={"Address"}
        type={"text"}
      />
      <InputField name={"confirm_address"} type={"hidden"} />
    </>
  )
}
