import { ErrorMessage, Field } from "formik"
import "./select-field.css"
import * as React from "react"
import PropTypes from "prop-types"

const SelectField = ({ name, placeholder, options, label, handleOnClick }) => (
  <div id={"select-field"}>
    <label htmlFor={`#${name}`}>{label}</label>
    <Field
      name={name}
      className={"form-control form-control-lg"}
      component={"select"}
      onClick={handleOnClick}
    >
      <option>{placeholder}</option>
      {options.map(option => (
        <option key={option.name} value={option.name}>
          {option.name}
        </option>
      ))}
    </Field>
    <ErrorMessage
      name={name}
      render={msg => (
        <div
          style={{
            color: "red",
            margin: "0 0 15px 0",
            fontWeight: 400,
            fontSize: "13px",
          }}
        >
          {msg}
        </div>
      )}
    />
  </div>
)

SelectField.defaultProps = {
  name: "",
  placeholder: "",
  options: [],
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func,
}

export default SelectField
