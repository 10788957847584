import React from "react"
import "./membership.css"
import MembershipForm from "../Forms/Membership/membership-form"

interface ContactProps {}
const Membership: React.FC<ContactProps> = ({}) => {
  return (
    <section id="membership" className="membership">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Membership</h2>
        </div>
        <div className="row mt-5 align-items-center">
          <div className="col-lg-8 offset-lg-2 mt-5 mt-lg-0">
            <MembershipForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Membership
