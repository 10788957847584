import axios from "axios"
import { getFirstName, getLastName } from "../../../utll"
import { SendVerificationEmail } from "../../../utll/firebase"

export const submitMembershipForm = async membershipFormData => {
  const hubSpotData = {
    portalId: "24963397",
    formId: "9d9c1c03-1960-4d6d-8dca-4b44a33b018c",
  }

  const contactFormURI = `https://api.hsforms.com/submissions/v3/integration/submit/${hubSpotData.portalId}/${hubSpotData.formId}`
  const body = createMembershipFormData(
    membershipFormData.title,
    membershipFormData.organisation_name,
    membershipFormData.phone,
    membershipFormData.email,
    membershipFormData.leader,
    membershipFormData.designation,
    membershipFormData.address,
    membershipFormData.number_of_churches_pastors
  )

  try {
    await axios.post(contactFormURI, body)
    const response = await SendVerificationEmail({
      email: membershipFormData.email,
      firstName: getFirstName(membershipFormData.leader),
    })
    return response.data
  } catch (error) {
    return {
      status: 400,
      message:
        "An error occured whilst submitting your membership form, please try again later.",
    }
  }
}

const createMembershipFormData = (
  title: string,
  organisation_name: string,
  phone: string,
  email: string,
  leader: string,
  designation: string,
  address: string,
  number_of_churches_pastors: number
) => {
  return {
    submittedAt: Date.now(),
    fields: [
      {
        name: "title",
        value: title,
      },
      {
        name: "firstname",
        value: getFirstName(leader),
      },
      {
        name: "lastname",
        value: getLastName(leader),
      },
      {
        name: "phone",
        value: phone,
      },
      {
        name: "email",
        value: email,
      },
      {
        name: "organisation_name",
        value: organisation_name,
      },
      {
        name: "designation",
        value: designation,
      },
      {
        name: "address",
        value: address,
      },
      {
        name: "number_of_churches_pastors",
        value: number_of_churches_pastors,
      },
      {
        name: "hs_lead_status",
        value: "New",
      },
    ],
    context: {
      pageUri: "https://www.sacoff.org",
      pageName: "Membership Page",
    },
  }
}
